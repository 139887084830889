/* global Redactor */

import onmount from 'onmount';
import katex from 'katex/dist/katex.js'; // eslint-disable-line import/no-unresolved, import/extensions

(function ($R) {
  $R.add('plugin', 'equation', {
    translations: {
      en: {
        change: 'Change',
        remove: 'Remove',
        equation: 'Equation'
      }
    },
    modals: {
      equation:
        "<div class='redactor-equation'>" +
          '<div data-js-equation-editor>' +
            '<math-field></math-field>' +
          '</div>' +
        '</div>'
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.opts = app.opts;
      this.toolbar = app.toolbar;
      this.component = app.component;
      this.insertion = app.insertion;
      this.inspector = app.inspector;
      this.selection = app.selection;
    },
    // messages
    onstarted() {
      if (katex) {
        this.renderKatex();
      } else {
        setTimeout(this.onstarted.bind(this), 100);
      }
      this.app.rootElement.addEventListener('redactor:sourceChanged', this.renderKatex.bind(this));
    },
    onmodal: {
      equation: {
        open($modal) {
          this.$modal = $modal;
          this.build($modal);
          onmount();
        },
        opened($modal) {
          this.mathfieldEl.focus();
          this.positionModal($modal);
        },
        insert() {
          this.insert();
        },
        closed($modal) {
          $modal.remove();
        }
      }
    },
    oncontextbar(e, contextbar) {
      const data = this.inspector.parse(e.target);
      if (data.isComponentType('equation')) {
        const node = data.getComponent();
        const buttons = {
          change: {
            title: this.lang.get('change'),
            api: 'plugin.equation.open',
            args: node
          },
          remove: {
            title: this.lang.get('remove'),
            api: 'plugin.equation.remove',
            args: node
          }
        };

        contextbar.set(e, node, buttons, 'bottom');
      }
    },

    // public
    start() {
      const obj = {
        title: this.lang.get('equation'),
        api: 'plugin.equation.open'
      };

      const $button = this.toolbar.addButton('equation', obj);
      $button.setIcon("<i class='material-icons'>functions</i>");
    },
    open() {
      const options = {
        title: this.lang.get('equation'),
        width: '735px',
        name: 'equation',
        handle: 'insert',
        commands: {
          cancel: { title: this.lang.get('cancel') },
          insert: { title: this.lang.get('save') }
        }
      };

      this.$currentItem = this.getCurrent();
      this.app.api('module.modal.build', options);
    },
    insert() {
      this.app.api('module.modal.close');

      const equation = this.mathfieldEl.getValue('latex-expanded') || this.mathfieldEl.getValue('ascii-math');
      if (!equation) return;

      const katexString = this.convertToKatex(equation);

      const $equation = this.component.create('equation');
      $equation.attr('data-latex', equation);
      $equation.html(katexString);

      this.insertion.insertRaw($equation);
    },
    remove(node) {
      this.component.remove(node);
    },

    // private
    getCurrent() {
      const current = this.selection.getCurrent();
      const data = this.inspector.parse(current);
      if (data.isComponentType('equation')) {
        return this.component.build(data.getComponent());
      }
      return null;
    },
    build($modal) {
      const $body = $modal.getBody();
      const $footer = $modal.getFooter();

      this.addDraggableAttributes($body.nodes[0].parentElement);
      this.initMathLive($body);

      $footer.find('[data-command="insert"]').data('js-insert', '');
    },
    initMathLive($body) {
      [this.mathfieldEl] = $body.find('math-field').nodes;

      const latex = this.getCurrentEquation();
      if (latex) this.mathfieldEl.setValue(latex);
      this.mathfieldEl.focus();
    },
    convertToKatex(equation) {
      let eq = equation;
      eq = eq.trim();
      eq = eq.replace(/\$/gi, '');
      eq = eq.replace(/\\$/gi, '');

      return katex.renderToString(eq, { throwOnError: false });
    },
    getCurrentEquation() {
      if (this.$currentItem) {
        const equationData = this.$currentItem;

        return equationData.attr('data-latex');
      }

      return false;
    },
    addDraggableAttributes(element) {
      element.setAttribute('data-js-draggable-dialog', '');
      element.setAttribute('data-js-dialog-container', '');
      element.querySelector('.redactor-modal-header').setAttribute('data-js-draggable-header', '');
    },
    positionModal($modal) {
      const windowHeight = $modal.$win.height();
      const adjustedWindowHeight = windowHeight - 275;
      const height = $modal.height();
      const marginTop = (adjustedWindowHeight / 2 - height / 2);

      if (height < windowHeight && marginTop !== 0) {
        $modal.css('margin-top', `${marginTop}px`);
      }
    },
    renderKatex() {
      const self = this;
      const dom = this.app.editor.getElement();
      const equations = dom.find("[data-redactor-type='equation']");

      Array.prototype.forEach.call(equations.nodes, (eq) => {
        const katexNode = self.convertToKatex(eq.dataset.latex);
        eq.innerHTML = katexNode;
      });
    }
  });
}(Redactor));
(function ($R) {
  $R.add('class', 'equation.component', {
    mixins: ['dom', 'component'],
    init(app, el) {
      this.app = app;

      // init
      return (el && el.cmnt !== undefined) ? el : this.initialize(el);
    },
    // public
    getData() {
      return {
        type: this.getType()
      };
    },

    // private
    initialize(el) {
      const element = el || '<span>';

      this.parse(element);
      this.initWrapper();
    },
    getType() {
      return this.text().trim().replace(/[\u200B-\u200D\uFEFF]/g, '');
    },
    initWrapper() {
      this.addClass('redactor-component');
      this.attr({
        'data-redactor-type': 'equation',
        tabindex: '-1',
        contenteditable: false
      });
    }
  });
}(Redactor));
