/* global MathfieldElement, mathVirtualKeyboard, I18n */

import onmount from 'onmount';
import { MATRIX_ROWS } from '@vendor/mathlive/matrix-keyboard';
import { menuItems } from '@vendor/mathlive/context-menu';

onmount('[data-js-equation-editor]', function () {
  const input = this.querySelector('[data-js-equation-input]');
  const mathfieldEl = this.querySelector('math-field');

  function handleContentChange() {
    if (!input) return;

    const equation = mathfieldEl.getValue('latex-expanded') || mathfieldEl.getValue('ascii-math');
    input.value = equation;
  }

  function handleKeyPress(e) {
    if (e.key === 'Escape') e.stopPropagation();
  }

  mathfieldEl.inlineShortcuts = { ...mathfieldEl.inlineShortcuts, "''": '^{\\prime\\prime}' };
  MathfieldElement.soundsDirectory = null;
  MathfieldElement.locale = I18n.locale;
  mathVirtualKeyboard.layouts = [
    'numeric', 'symbols', 'alphabetic', 'greek',
    {
      label: '[&square;&square;]',
      tooltip: 'Matrix',
      layer: 'matrix-layer',
      rows: MATRIX_ROWS
    }
  ];

  mathfieldEl.menuItems = menuItems(mathfieldEl, mathfieldEl._mathfield); // eslint-disable-line no-underscore-dangle
  mathfieldEl.mathVirtualKeyboardPolicy = 'auto';
  mathfieldEl.setAttribute('data-mdc-dialog-initial-focus', true);
  mathfieldEl.addEventListener('input', handleContentChange);
  mathfieldEl.addEventListener('focusin', () => mathVirtualKeyboard.show());
  mathfieldEl.addEventListener('keydown', handleKeyPress);
  mathfieldEl.addEventListener('keyup', handleKeyPress);
});
