import onmount from 'onmount';
import { formatTime } from '@modules/format-time';

onmount('[data-js-display-timer]', function () {
  const self = this;
  const { url } = this.dataset;
  const displayTimerDefault = true;
  const timer = document.querySelector('[data-js-timer]');
  if (!timer) return;

  let deadline;
  let displayTimer;
  let remainingTime;

  function updateTimerVisibility() {
    localStorage.setItem('display-timer', displayTimer);
    self.checked = displayTimer;

    if (displayTimer) {
      timer.classList.remove('d-none');
    } else {
      timer.classList.add('d-none');
    }
  }

  function setTimerVisibility() {
    if (localStorage.getItem('display-timer')) {
      displayTimer = localStorage.getItem('display-timer') === 'true';
    } else {
      displayTimer = displayTimerDefault;
    }
    updateTimerVisibility();
  }

  function handleDisplayTimerClick() {
    displayTimer = self.checked;
    updateTimerVisibility();
  }

  function currentTime() {
    return Date.now() / 1000;
  }

  function endTimer() {
    timer.innerText = '0:00:00';
    document.querySelector(`form[action="${url}"] [data-js-prev]`).click();
  }

  function setDeadline() {
    remainingTime = timer.getAttribute('data-time-remaining');
    deadline = currentTime() + parseFloat(timer.dataset.timeRemaining);
  }

  function updateTimer() {
    if (timer.getAttribute('data-time-remaining') !== remainingTime) {
      setDeadline();
    }

    const remaining = deadline - currentTime();
    if (remaining <= 0) {
      endTimer();
      return;
    }

    const formattedTime = formatTime(remaining);
    if (timer.innerText !== formattedTime) {
      timer.innerText = formattedTime;

      const event = new CustomEvent('quizDurationChanged', { detail: remaining });
      document.dispatchEvent(event);
    }

    requestAnimationFrame(updateTimer);
  }

  if (timer.hasAttribute('data-time-remaining')) {
    setDeadline();
    requestAnimationFrame(updateTimer);
  }

  setTimerVisibility();
  self.addEventListener('click', handleDisplayTimerClick);
  timer.addEventListener('updateTimer', updateTimer);
});
