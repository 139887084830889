/* global I18n */

import Rails from '@rails/ujs';

import onmount from 'onmount';
import { getRedactor } from '@redactor/helpers';

onmount('[data-js-grade-formula]', function () {
  const self = this;
  const { url } = this.dataset;

  function unescapeHtml(htmlString) {
    const replacements = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&#039;': "'",
      '&quot;': '"',
      '&nbsp;': ' '
    };
    if (!htmlString) return '';
    return htmlString.replace(/(&amp;|&lt;|&gt;|&#039;|&quot;|&nbsp;)/g, (match) => replacements[match]);
  }

  function syncing(html) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(html, 'text/html');

    [...htmlDoc.querySelectorAll('[data-redactor-type="grade-formula-variable"]')].forEach((el) => {
      el.outerHTML = el.dataset.value;
    });

    const { firstChild } = htmlDoc.body;
    if (firstChild) return firstChild.innerText;

    return '';
  }

  function started() {
    this.broadcast('hardsync');
  }

  const options = {
    autoparseStart: false,
    buttons: [],
    enterKey: false,
    lang: I18n.locale,
    plugins: ['gradeFormula'],
    callbacks: { syncing, started }
  };
  if (options.lang === 'nl_vo') options.lang = 'nl';

  function initRedactor(variables) {
    options.variables = variables;

    getRedactor().then((Redactor) => {
      self.redactor = Redactor(self, options);

      if (self.getAttribute('disabled')) self.redactor.enableReadOnly();
    });
  }

  function getVariables() {
    if (!url) {
      initRedactor([{ value: 'points' }, { value: 'total' }]);
      return;
    }

    Rails.ajax({ url, type: 'GET', success: (data) => initRedactor(data) });
  }

  getVariables();
}, function () {
  this.redactor.stop();
});
