import { Controller } from '@hotwired/stimulus';
import { useClickOutside, useDebounce } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/hotkeys';

export default class extends Controller {
  static debounces = ['search'];

  static targets = ['query', 'menu', 'result', 'results', 'submit'];

  currentIndex = -1;

  connect() {
    useClickOutside(this);
    useDebounce(this, { wait: 300 });
    useHotkeys(this, {
      '/': [this.focus],
      down: [this.navigate],
      up: [this.navigate],
      enter: [this.navigateToResult]
    });
  }

  focus(event) {
    event.preventDefault();
    this.resultsTarget.innerHTML = '';
    this.submitTarget.click();
    this.open();
    this.queryTarget.focus();
  }

  open() {
    this.menuTarget.classList.remove('d-none');
  }

  closed() {
    return this.menuTarget.classList.contains('d-none');
  }

  search() {
    this.submitTarget.click();
  }

  close() {
    this.menuTarget.classList.add('d-none');
    this.queryTarget.value = '';
    this.queryTarget.blur();
  }

  updateSelectedResult() {
    this.resultTargets.forEach((result, index) => {
      if (index === this.currentIndex) {
        result.classList.add('outline');
        result.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      } else {
        result.classList.remove('outline');
      }
    });
  }

  navigate(event) {
    if (this.closed()) return;

    event.preventDefault();

    if (event.key === 'ArrowDown') {
      this.navigateDown();
    } else if (event.key === 'ArrowUp') {
      this.navigateUp();
    }

    this.updateSelectedResult();
  }

  navigateDown() {
    if (this.currentIndex < this.resultTargets.length - 1) {
      this.currentIndex += 1;
    } else {
      this.currentIndex = 0;
    }
  }

  navigateUp() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = this.resultTargets.length - 1;
    }
  }

  navigateToResult(event) {
    if (this.closed()) return;

    event.preventDefault();
    event.stopImmediatePropagation();
    this.resultTargets[this.currentIndex].click();
  }

  clickOutside() {
    this.close();
  }

  resultTargetDisconnected() {
    this.currentIndex = -1;
  }
}
