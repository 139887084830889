/* global Redactor */
import de from '@locales/redactor/de.json';
import en from '@locales/redactor/en.json';
import fr from '@locales/redactor/fr.json';
import lv from '@locales/redactor/lv.json'
import nl from '@locales/redactor/nl.json';

(function ($R) {
  $R.lang.de = de.de.js.redactor;
  $R.lang.en = en.en.js.redactor;
  $R.lang.fr = fr.fr.js.redactor;
  $R.lang.lv = lv.lv.js.redactor;
  $R.lang.nl = nl.nl.js.redactor;
}(Redactor));
