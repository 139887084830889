import onmount from 'onmount';

onmount('[data-js-filter]', function () {
  const filterItems = this.querySelectorAll('[data-js-filter-item]');

  function escapeQuery(query) {
    return query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  function handleSelectFilterItem(self) {
    const url = new URL(window.location.href);
    let query = url.searchParams.get('q');
    let content = '';

    if (!self.classList.contains('filter-item--selected')) {
      content = self.getAttribute('data-query');
    }

    if (query === null) {
      url.searchParams.set('q', `${content}`);
    } else {
      filterItems.forEach((filterItem) => {
        if (filterItem.dataset.query) {
          const regex = new RegExp(`(^| )${escapeQuery(filterItem.dataset.query)}`);
          query = query.replace(regex, '');
        }
      });
      query = query.trim();

      if (query === '' && content === '') {
        url.searchParams.delete('q');
      } else if (query === '') {
        url.searchParams.set('q', `${content}`);
      } else if (content === '') {
        url.searchParams.set('q', `${query}`);
      } else {
        url.searchParams.set('q', `${query} ${content}`);
      }
    }

    window.location.assign(url);
  }

  function handleClick() {
    handleSelectFilterItem(this);
  }

  function handleEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSelectFilterItem(this);
    }
  }

  filterItems.forEach((item) => (item).addEventListener('click', handleClick));
  filterItems.forEach((item) => (item).addEventListener('keydown', handleEnter));
});
