/* global Redactor */

import { handleEvents } from '@modules/redactor/autocomplete-handle-list';
import { escapeHtml } from '@modules/custom';
import Fuse from 'fuse.js';

(function ($R) {
  $R.add('plugin', 'gradeFormula', {
    init(app) {
      const self = this;
      this.app = app;
      this.opts = app.opts;
      this.lang = app.lang;
      this.utils = app.utils;
      this.$doc = app.$doc;
      this.$body = app.$body;
      this.editor = app.editor;
      this.marker = app.marker;
      this.keycodes = app.keycodes;
      this.insertion = app.insertion;
      this.component = app.component;
      this.selection = app.selection;

      // local
      this.handleStr = '';
      this.handleLen = 35;
      this.handleRegex = /[a-zA-Z_](\w*|\d*)$/;
      this.data = this.parseData();

      const inputValue = escapeHtml(this.editor.element.rootElement.value);
      const values = this.data.map((x) => this.escapeRegex(x.value)).join('|');
      const variableRegex = new RegExp(`(${values})`, 'g');
      const parsedValue = inputValue.replaceAll(variableRegex, this.buildComponentFromValue.bind(this));

      this.editor.element.rootElement.value = parsedValue;

      self.fuse = new Fuse([], { keys: ['name'] });
    },
    escapeRegex(string) {
      return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
    },
    parseData() {
      return this.opts.variables.map((variable) => {
        const name = variable.name || this.lang.get(`grade-formula-${variable.value.replace('_', '-')}`);

        return { value: variable.value, name };
      });
    },
    buildComponentFromValue(value) {
      const { name } = this.data.find((x) => x.value === value);
      return this.utils.parseHtml(this.buildComponent(name, value)).html + this.opts.markerChar;
    },
    buildComponentFromName(match) {
      const { name, value } = this.data.find((x) => x.name.toLowerCase() === match.toLowerCase());
      return this.utils.parseHtml(this.buildComponent(name, value)).html;
    },
    buildComponent(name, value) {
      const $variable = this.component.create('grade-formula-variable');
      $variable.attr('data-value', value);
      $variable.html(name);

      return $variable;
    },
    // events
    onpasting(html) {
      const names = this.data.map((x) => x.name);
      const sorted = names.sort((a, b) => b.length - a.length).join('|');
      const variableRegex = new RegExp(`\\b(${sorted})\\b`, 'gi');
      let parsedValue = html;

      if (parsedValue.match(/<span.*?<\/span>/)) {
        parsedValue.match(/<span.*?<\/span>/).forEach((span) => {
          if (span.match(variableRegex)) {
            parsedValue = parsedValue.replaceAll(`${span}`, this.buildComponentFromName(span.match(variableRegex)[0]));
          }
        });
      }

      return parsedValue;
    },
    // public
    start() {
      const editorEl = this.editor.getElement();
      editorEl.on('keyup.redactor-plugin-grade-formula', this.handle.bind(this));
    },
    stop() {
      const editorEl = this.editor.getElement();

      editorEl.off('.redactor-plugin-grade-formula');
      this.$doc.off('.redactor-plugin-grade-formula');

      $R.dom('#redactor-grade-formula-list').remove();
    },

    // private
    handle(e) {
      const key = e.which;
      const ctrl = e.ctrlKey || e.metaKey;
      const arrows = [37, 38, 39, 40];

      if (key === this.keycodes.DELETE ||
          key === this.keycodes.ESC ||
          key === this.keycodes.SHIFT ||
          ctrl ||
          (arrows.indexOf(key) !== -1)) return;

      this.handleStr = this.selection.getTextBeforeCaret(this.handleLen);

      // detect
      if (this.handleRegex.test(this.handleStr)) {
        [this.handleStr] = this.handleStr.match(this.handleRegex);

        this.load();
      } else if (this.isShown()) {
        this.hide();
      }
    },
    load() {
      this.build();
      this.buildData(this.data);
    },
    build() {
      this.$list = $R.dom('#redactor-handle-list');
      if (this.$list.length === 0) {
        this.$list = $R.dom('<div id="redactor-handle-list">');
        this.$body.append(this.$list);
      }
    },
    buildData(data) {
      this.data = data;
      if (!this.fuse._docs.length) this.fuse.setCollection(data); // eslint-disable-line no-underscore-dangle

      this.update();
      this.show();
    },
    update() {
      this.$list.html('');

      const results = (this.handleStr ? this.fuse.search(this.handleStr).map((x) => x.item) : this.fuse._docs); // eslint-disable-line no-underscore-dangle
      for (let index = 0; index < results.length; index += 1) {
        const $item = $R.dom('<a href="#">');
        if (index === 0) $item.addClass('selected');

        $item.html(results[index].name);
        $item.attr('data-value', results[index].value);
        $item.on('click', this.replace.bind(this));

        this.$list.append($item);
      }

      // position
      const pos = this.selection.getPosition();

      this.$list.css({
        top: `${(pos.top + pos.height + this.$doc.scrollTop())}px`,
        left: `${pos.left}px`
      });
    },
    isShown() {
      return (this.$list && this.$list.hasClass('open'));
    },
    show() {
      this.$list.addClass('open');
      this.$list.show();

      this.$doc.off('.redactor-plugin-handle');
      this.$doc.on('click.redactor-plugin-handle keydown.redactor-plugin-handle', handleEvents.bind(this));
    },
    hide(e) {
      let hidable = false;
      const key = (e && e.which);

      if (!e) hidable = true;
      else if (e.type === 'click' ||
               key === this.keycodes.ESC ||
               key === this.keycodes.ENTER ||
               key === this.keycodes.SPACE) hidable = true;

      if (hidable) {
        this.$list.removeClass('open');
        this.$list.hide();
        this.reset();
      }
    },
    reset() {
      this.handleStr = '';
    },
    replace(e) {
      e.preventDefault();

      const $item = $R.dom(e.target);
      const $variable = this.buildComponent($item.html(), $item.attr('data-value'));

      const marker = this.marker.insert('start');
      const current = marker.previousSibling;
      let currentText = current.textContent;

      currentText = currentText.replace(this.handleRegex, '');
      current.textContent = currentText;

      return this.insertion.insertRaw($variable);
    }
  });
}(Redactor));

(function ($R) {
  $R.add('class', 'grade-formula-variable.component', {
    mixins: ['dom', 'component'],
    init(app, el) {
      this.app = app;
      this.utils = app.utils;

      return (el && el.cmnt !== undefined) ? el : this.initialize(el);
    },

    // public
    getData() {
      return {
        type: this.getType()
      };
    },

    // private
    initialize(el) {
      const element = el || '<span>';

      this.parse(element);
      this.initWrapper();
    },
    getType() {
      const text = this.text().trim();
      return this.utils.removeInvisibleChars(text);
    },
    initWrapper() {
      this.addClass('redactor-component chip');
      this.attr({
        'data-redactor-type': 'grade-formula-variable',
        tabindex: '-1',
        contenteditable: false
      });
    }
  });
}(Redactor));
