import { Controller } from '@hotwired/stimulus';
import refreshTextFieldLabel from '@components/text_field';

const HOUR = 60 * 60 * 1000;

export default class extends Controller {
  static targets = ['start', 'end'];

  static values = { start: String };

  connect() {
    this.update();
  }

  update() {
    this.startValue = this.startTarget.value;
  }

  newEndValue(startValue, previousStartValue) {
    if (!startValue) return '';

    let delta = HOUR;
    if (previousStartValue && this.endTarget.value) {
      delta = Math.max(new Date(this.endTarget.value) - new Date(previousStartValue), HOUR);
    }

    return new Date(startValue).getTime() + delta;
  }

  startValueChanged(value, previousValue) {
    if (!this.endTarget._flatpickr) return;
    if (!(value || previousValue)) return;
    if (!(value || this.endTarget.value)) return;

    this.endTarget._flatpickr.setDate(this.newEndValue(value, previousValue), false);

    [this.startTarget, this.endTarget].forEach((n) => {
      const mdcTextField = n.closest('.mdc-text-field');
      if (mdcTextField) refreshTextFieldLabel(mdcTextField);
    });
  }
}
