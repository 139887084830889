import onmount from 'onmount';
import { loadCommentList, focusHighlight, blurHighlight } from '@modules/highlight-worker';

onmount('[data-js-highlight-comment-panel]', function () {
  /* FUNCTIONS */
  function loadActiveSubmissionComments(submissionId) {
    const submission = document.querySelector(`[data-js-quiz-subquestion][data-submission-id='${submissionId}']`);
    if (submission && submission.querySelector('[data-js-highlightable]')) {
      const highlightable = submission.querySelector('[data-js-highlightable]');
      loadCommentList(highlightable.dataset.highlightableUrl);
    } else {
      document.querySelector('[data-js-highlight-comment-panel]').innerHTML = '';
      document.querySelector('[data-js-highlight-comment-panel-empty-state]').classList.remove('d-none');
    }
    document.querySelector('[data-js-annotation-comment-panel]').innerHTML = '';
    document.querySelector('[data-js-annotation-comment-panel-empty-state]').classList.add('d-none');
  }

  function handleSubmissionChangeEvent(e) {
    const { submissionId } = e.detail;
    loadActiveSubmissionComments(submissionId);
  }
  this.handleSubmissionChangeEvent = handleSubmissionChangeEvent;

  function handleClickEvent(e) {
    if (e.target.closest('[data-js-highlight]')) {
      const highlightId = e.target.closest('[data-js-highlight]').dataset.jsHighlight;
      focusHighlight(highlightId);
    } else if (e.target.closest('[data-js-back-to-overview]')) {
      blurHighlight();
    }
  }

  function handleHighlightCommentCreatedEvent(e) {
    const { highlightId, commentCount } = e.detail;
    const node = document.querySelector(`[data-highlight-id="${highlightId}"]`);

    if (!node) return;
    node.setAttribute('data-comments', commentCount);
  }
  this.handleHighlightCommentCreatedEvent = handleHighlightCommentCreatedEvent;

  /* EVENT HANDLERS */
  document.addEventListener('grading:submissionChangedEvent', this.handleSubmissionChangeEvent);
  document.addEventListener('highlightComment:created', this.handleHighlightCommentCreatedEvent);
  document.addEventListener('highlightComment:destroyed', this.handleHighlightCommentCreatedEvent);
  this.addEventListener('click', handleClickEvent);

  /* INITIALIZERS */
  const { submissionId } = document.querySelector('[data-js-review-ids]').dataset;
  loadActiveSubmissionComments(submissionId);
}, function () {
  document.removeEventListener('grading:submissionChangedEvent', this.handleSubmissionChangeEvent);
  document.removeEventListener('highlightComment:created', this.handleHighlightCommentCreatedEvent);
  document.removeEventListener('highlightComment:destroyed', this.handleHighlightCommentCreatedEvent);
});
