import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['search'];

  static targets = ['clear', 'query', 'submit'];

  connect() {
    useDebounce(this, { wait: 300 });
    this.toggleClearButtonVisibility();
  }

  search() {
    this.toggleClearButtonVisibility();
    if (this.hasSubmitTarget) { this.submitTarget.click(); }
  }

  clear() {
    this.queryTarget.value = '';
    this.clearTarget.classList.add('d-none');

    if (this.hasSubmitTarget) { this.submitTarget.click(); }
    this.queryTarget.focus();
  }

  toggleClearButtonVisibility() {
    if (this.queryTarget.value.length > 0) {
      this.clearTarget.classList.remove('d-none');
    } else {
      this.clearTarget.classList.add('d-none');
    }
  }
}
