import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

// Connects to data-controller="split-button"
export default class extends Controller {
  static targets = ['hiddenField', 'label'];

  select(event) {
    this.hiddenFieldTargets.forEach((target) => {
      const { objectName } = target.dataset;
      const value = event.target.dataset[objectName];
      target.value = value;
      Cookies.set(objectName, value, { expires: 7 });
    });

    this.labelTarget.textContent = event.target.textContent;
  }
}
