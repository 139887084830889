export async function getRedactor() {
  const [module] = await Promise.all([
    import(/* webpackChunkName: "redactor" */ '@redactor/redactor'),
    import(/* webpackChunkName: "redactor" */ '@redactor/langs'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/alignment'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/audio-recording'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/counter'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/equation'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/filemanager-adjusted'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/fontcolor'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/fullscreen-adjusted'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/grade-formula'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/handle'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/imagemanager-adjusted'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/limiter'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/option-list'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/shortcut-autocomplete'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/shortcut'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/specialchars'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/table'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/twemoji'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/variable'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/video'),
    import(/* webpackChunkName: "redactor" */ '@redactor/plugins/webcam-screenshot')
  ]);
  return module.default;
}
