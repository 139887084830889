/* global I18n, google */

import onmount from 'onmount';
import snackbar from '@components/snackbar';
import Rails from '@rails/ujs';
import { debounce } from '@modules/custom';

onmount('[data-js-histogram]', function () {
  const wrapper = this.closest('[data-js-graph-wrapper]');
  const chart = this.querySelector('[data-js-chart]');
  const { url } = this.dataset;

  function setMinMeanMax(response) {
    wrapper.querySelector('[data-js-min]').innerText = response.min || 0;
    wrapper.querySelector('[data-js-median]').innerText = response.median || 0;
    wrapper.querySelector('[data-js-mean]').innerText = response.mean || 0;
    wrapper.querySelector('[data-js-max]').innerText = response.max || 0;
  }

  function replaceZeroWithNull(data) {
    for (let i = 0; i < data.length; i += 1) {
      const row = data[i];
      const failed = data[i][1];
      const passed = data[i][2];
      data[i].splice(2, 0, failed + I18n.t('js.analytics.overview.participants'));
      data[i][row.length] = passed + I18n.t('js.analytics.overview.participants');

      for (let j = 0; j < row.length; j += 1) {
        const cell = row[j];

        if (cell === 0) data[i][j] = null;
      }
    }

    return data;
  }

  function drawHistogram(response) {
    setMinMeanMax(response);

    const data = new google.visualization.DataTable();
    data.addColumn('string', I18n.t('js.analytics.overview.grade'));
    data.addColumn('number', I18n.t('js.analytics.overview.failed'));
    data.addColumn({
      type: 'string',
      role: 'tooltip'
    });
    data.addColumn('number', I18n.t('js.analytics.overview.passed'));
    data.addColumn({
      type: 'string',
      role: 'tooltip'
    });
    data.addRows(replaceZeroWithNull(response.data));

    const options = {
      titleTextStyle: {
        fontSize: 20,
        bold: true,
        color: '#000000'
      },
      backgroundColor: 'transparent',
      colors: ['orange', '#000000'],
      chartArea: {
        width: '80%'
      },
      hAxis: {
        title: I18n.t('js.analytics.overview.grade'),
        titleTextStyle: {
          color: '#000000',
          italic: false
        },
        textStyle: {
          color: '#000000'
        }
      },
      vAxis: {
        title: I18n.t('js.analytics.overview.number_of_participants'),
        minValue: 0,
        ticks: response.ticks,
        titleTextStyle: {
          color: '#000000',
          italic: false
        },
        textStyle: {
          color: '#000000'
        },
        baselineColor: '#000000'
      },
      legend: {
        position: chart.hasAttribute('data-js-legend') ? 'top' : 'none'
      },
      material: true,
      isStacked: true,
      series: {
        0: {
          color: '#FF715B'
        },
        1: {
          color: '#00B87B'
        }
      }
    };
    const googleChart = new google.visualization.ColumnChart(chart);
    googleChart.draw(data, options);
  }

  function getHistogramData() {
    Rails.ajax({
      url,
      type: 'GET',
      success: (response) => drawHistogram(response),
      error: (e) => snackbar(e, true)
    });
  }
  this.getHistogramData = debounce(getHistogramData);

  google.charts.load('current', { packages: ['corechart'] });
  google.charts.setOnLoadCallback(getHistogramData);
  window.addEventListener('resize', this.getHistogramData);
}, function () {
  window.removeEventListener('resize', this.getHistogramData);
});
