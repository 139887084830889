/* global I18n */

function inMatrix(mf) {
  return !!mf.model.parentEnvironment?.array;
}

export function menuItems(mathfieldEl, mf) {
  const defaultMenuItems = mathfieldEl.menuItems;
  const borders = defaultMenuItems.find((item) => (item.containerClass === 'menu-container-border'));
  borders.label = I18n.t('js.mathlive.borders');

  const insertMatrix = defaultMenuItems.find((item) => (item.id === 'insert-matrix'));
  insertMatrix.visible = () => (mf.isSelectionEditable && !inMatrix(mf));
  insertMatrix.label = I18n.t('js.mathlive.insert_matrix');

  return [
    {
      label: I18n.t('js.mathlive.add_row_before'),
      id: 'add-row-above',
      onMenuSelect: () => mf.executeCommand('addRowBefore'),
      visible: () => inMatrix(mf)
    },
    {
      label: I18n.t('js.mathlive.remove_row'),
      id: 'remove-row',
      onMenuSelect: () => mf.executeCommand('removeRow'),
      visible: () => inMatrix(mf)
    },
    {
      label: I18n.t('js.mathlive.add_row_after'),
      id: 'add-row-below',
      onMenuSelect: () => mf.executeCommand('addRowAfter'),
      visible: () => inMatrix(mf)
    },
    {
      type: 'divider'
    },
    {
      label: I18n.t('js.mathlive.add_column_before'),
      id: 'add-column-before',
      onMenuSelect: () => mf.executeCommand('addColumnBefore'),
      visible: () => inMatrix(mf)
    },
    {
      label: I18n.t('js.mathlive.remove_column'),
      id: 'delete-column',
      onMenuSelect: () => mf.executeCommand('removeColumn'),
      visible: () => inMatrix(mf)
    },
    {
      label: I18n.t('js.mathlive.add_column_after'),
      id: 'add-column-after',
      onMenuSelect: () => mf.executeCommand('addColumnAfter'),
      visible: () => inMatrix(mf)
    },
    {
      type: 'divider'
    },
    borders,
    insertMatrix
  ];
}
