import onmount from 'onmount';
import { HighlightWorker } from '@modules/highlight-worker';

onmount('[data-js-highlightable]', function () {
  this.worker = null;

  const self = this;
  const subquestion = this.closest('[data-js-quiz-subquestion]');
  const { submissionId } = subquestion.dataset;
  const activeSubmissionId = document.querySelector('[data-js-review-ids]').dataset.submissionId;
  const readOnly = this.dataset.readOnly === 'true';
  this.disabled = submissionId !== activeSubmissionId;

  const { highlightableId, highlightableUrl } = this.dataset;

  /* FUNCTIONS */
  function displayHighlights() {
    self.worker = new HighlightWorker(self, {
      nodeIdentifierKey: 'chnode',
      disabled: self.disabled,
      highlightableId,
      highlightableUrl,
      readOnly,
      submissionId
    });

    self.worker.init();
  }

  function enableContentHighlights() {
    self.worker.settings.disabled = false;
    self.disabled = false;
    if (!readOnly) {
      self.worker.element.addEventListener('mouseup', self.worker.handleMouseUp);
    }
  }

  function disableContentHighlights() {
    self.worker.element.removeEventListener('mouseup', self.worker.handleMouseUp);
    self.worker.settings.disabled = true;
    self.disabled = true;
  }

  function handleSubmissionChangeEvent(e) {
    if (submissionId === e.detail.submissionId.toString()) {
      enableContentHighlights();
    } else if (!self.disabled) {
      disableContentHighlights();
    }
  }
  this.handleSubmissionChangeEvent = handleSubmissionChangeEvent.bind(this);

  /* INITIALIZATION */
  displayHighlights();

  /* EVENT HANDLERS */
  document.addEventListener('grading:submissionChangedEvent', this.handleSubmissionChangeEvent);
}, function () {
  document.removeEventListener('grading:submissionChangedEvent', this.handleSubmissionChangeEvent);
  this.worker.destroy();
  this.worker = null;
});
