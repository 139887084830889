import { Controller } from '@hotwired/stimulus';
import { showValidationMessages } from '@modules/form-responses';

export default class extends Controller {
  static targets = ['submitButton'];

  updateSubmitDisabledState() {
    if (!this.hasSubmitButtonTarget) return;

    if (this.element.checkValidity() && this.mdcSelectsValid()) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  mdcSelectsValid() {
    return Array.from(this.element.querySelectorAll('[data-mdc-auto-init="MDCSelect"]')).every((select) => {
      if (select.MDCSelect) return select.MDCSelect.valid;

      return false;
    });
  }

  displayValidationErrors(event) {
    if (event.detail.fetchResponse.response.status !== 422) return;

    event.detail.fetchResponse.response.json().then((errors) => {
      showValidationMessages(errors, this.element.closest('form'));
    });
  }

  connect() {
    this.updateSubmitDisabledState();
  }
}
