import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['gradingTypeField', 'partialScoringField', 'zeroBoundField'];

  update(e) {
    const { gradingType, partialScoring, zeroBound } = e.target.dataset;

    this.gradingTypeFieldTarget.value = gradingType;
    this.partialScoringFieldTarget.checked = partialScoring === 'true';
    this.zeroBoundFieldTarget.checked = zeroBound === 'true';
  }
}
