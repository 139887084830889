import { Controller } from '@hotwired/stimulus';
import { useMutation } from 'stimulus-use';

export default class extends Controller {
  static targets = ['list', 'submit'];

  connect() {
    useMutation(this, { element: this.listTarget, childList: true });
  }

  updateSubmitButton() {
    if (this.listTarget.querySelector('input[type="checkbox"]:checked')) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (typeof node.querySelector === 'undefined') return;

          const checkbox = node.querySelector('input[type="checkbox"]');
          if (checkbox) checkbox.setAttribute('data-action', 'change->dialog-action#updateSubmitButton');
        });
      }
    });
  }
}
