import onmount from 'onmount';
import { MDCDialog } from '@material/dialog';
import mdcAutoInit from '@material/auto-init';

onmount('[data-mdc-dialog]', function () {
  const self = this;

  if (!this.MDCDialog) {
    this.MDCDialog = new MDCDialog(this);
    this.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  this.MDCDialog.autoStackButtons = false;
  if (this.hasAttribute('data-js-non-dismissable')) {
    this.MDCDialog.scrimClickAction = '';
    this.MDCDialog.escapeKeyAction = '';
  }

  // Functions
  function handleDialogOpened() {
    document.body.dataset.hotkeysDisabled = true;
    mdcAutoInit();

    const focusEl = this.querySelector('[data-mdc-dialog-initial-focus]');
    if (focusEl && focusEl.hasAttribute('data-js-redactor')) {
      focusEl.redactor.editor.endFocus();
    } else if (focusEl) {
      focusEl.focus();
    }

    this.querySelectorAll('.mdc-ripple-upgraded').forEach((ripple) => {
      if (ripple.MDCCheckbox) ripple.MDCCheckbox.ripple.layout();
    });
  }
  this.handleDialogOpened = handleDialogOpened.bind(this);

  function handleDialogClosed() {
    delete document.body.dataset.hotkeysDisabled;
    if (this.hasAttribute('data-mdc-dialog-remove-on-close')) {
      setTimeout(() => {
        self.remove();
        onmount();
      }, 100);
    }
  }
  this.handleDialogClosed = handleDialogClosed.bind(this);

  if (this.hasAttribute('data-mdc-dialog-open')) {
    this.MDCDialog.open();
  }

  this.MDCDialog.listen('MDCDialog:opened', this.handleDialogOpened);
  this.MDCDialog.listen('MDCDialog:closed', this.handleDialogClosed);
}, function () {
  this.MDCDialog.unlisten('MDCDialog:opened', this.handleDialogOpened);
  this.MDCDialog.unlisten('MDCDialog:closed', this.handleDialogClosed);
  this.MDCDialog.destroy();
});
