import './ace-editor/ace-editor';
import './ace-editor/select-language';
import './analytics/histogram';
import './analytics/rank-correlation';
import './assignment-verification/accessibility';
import './assignments/automatic-scoring';
import './assignments/choice-sort-event';
import './assignments/generate-password';
import './assignments/new';
import './assignments/reuse-template';
import './auto-save';
import './blueprints/blueprint-objectives';
import './charts/bar-chart';
import './charts/certainty_levels/centration';
import './charts/column-chart';
import './charts/compare-chart';
import './charts/donut';
import './charts/longitudinal-chart';
import './charts/radar';
import './chats/panel';
import './collapsible';
import './confirm-click';
import './conversations/conversation_settings';
import './cookies';
import './copy-to-clipboard';
import './dashboard/active-students';
import './dashboard/assignment-calendar';
import './dashboard/instructors';
import './dashboard/live';
import './dashboard/login-activity';
import './dashboard/results_forecast';
import './dashboard/stats';
import './devise/password-validator';
import './devise/two-factor';
import './digital_test/radio-button';
import './digital_test/proctorio';
import './digital_test/strikethrough';
import './emoji';
import './equations/equation-dialog';
import './equations/equation-editor';
import './equations/equation-solution';
import './feedback';
import './filter';
import './filters/clear';
import './filters/date';
import './filters/filter-list';
import './filters/filter';
import './filters/filter_dialog';
import './filters/multi-select';
import './filters/range';
import './filters/search-filter';
import './flatpickr';
import './generator-criteria/answer';
import './generator-criteria/bubblesheet';
import './generator-criteria/calculate-points';
import './generator-criteria/choice-sort-actions';
import './generator-criteria/choices';
import './generator-criteria/cocoon-add';
import './generator-criteria/code-editor';
import './generator-criteria/coding-tests';
import './generator-criteria/component-sortable';
import './generator-criteria/copy-question';
import './generator-criteria/criterium-edit';
import './generator-criteria/equation-solver';
import './generator-criteria/exam-panel';
import './generator-criteria/generate-status';
import './generator-criteria/hotspot-area-edit';
import './generator-criteria/hotspot-areas';
import './generator-criteria/item-positions';
import './generator-criteria/move-component';
import './generator-criteria/option-items';
import './generator-criteria/option-list';
import './generator-criteria/question';
import './generator-criteria/questions';
import './generator-criteria/quiz-preview';
import './generator-criteria/response_rules';
import './generator-criteria/subelement-edit';
import './generator-criteria/subquestion-edit-grading-options';
import './generator-criteria/subquestion-edit';
import './generator-criteria/synchronizable';
import './grade-formula';
import './highlight/highlight-comment-panel';
import './highlight/highlight-comment';
import './highlight/highlightable';
import './import-file';
import './import/repo';
import './limit-items';
import './lockdown_browser/config_keys';
import './lockdown_browser/seb_config';
import './marketplace_assignments/plan_dialog';
import './materialio/checkbox-group';
import './materialio/checkbox';
import './materialio/data-table-pagination';
import './materialio/data-table';
import './materialio/dialog';
import './materialio/drawer';
import './materialio/menu';
import './materialio/select-menu';
import './materialio/switch';
import './miscellaneous/all_group_klass_select';
import './miscellaneous/autocomplete';
import './miscellaneous/client-search-field';
import './miscellaneous/custom_role_select';
import './miscellaneous/data-table-action';
import './miscellaneous/data-table-select-all';
import './miscellaneous/dialog-trigger';
import './miscellaneous/draggable-dialog';
import './miscellaneous/enlarge-images';
import './miscellaneous/expandable_list';
import './miscellaneous/file-upload';
import './miscellaneous/horizontal-scroll';
import './miscellaneous/indicator';
import './miscellaneous/inline-file-upload';
import './miscellaneous/katex';
import './miscellaneous/lazyload';
import './miscellaneous/load-more';
import './miscellaneous/load-on-scroll';
import './miscellaneous/notice';
import './miscellaneous/number-field';
import './miscellaneous/pending';
import './miscellaneous/pending_with_partial';
import './miscellaneous/pick-from-collection';
import './miscellaneous/radio-group';
import './miscellaneous/remote-form';
import './miscellaneous/remote_tooltip';
import './miscellaneous/remove-dialog';
import './miscellaneous/renable-disabled';
import './miscellaneous/save-and-restore-filters';
import './miscellaneous/select-menu-visibility';
import './miscellaneous/scroll-retainer';
import './miscellaneous/search-items';
import './miscellaneous/search-users';
import './miscellaneous/server-search-field';
import './miscellaneous/split-screen-tab';
import './miscellaneous/split-screen';
import './miscellaneous/stepper';
import './miscellaneous/time-ago';
import './miscellaneous/url-changed';
import './notifications/banner';
import './pdf-panel';
import './print_orders/form';
import './publication_timeslots/form';
import './publications/form';
import './question_groups/question-group-events';
import './question_groups/question-group-sortable';
import './record-page-view';
import './redactor/redactor-content';
import './redactor/redactor';
import './render-notice';
import './repo_assignments/repo_assignments_questions';
import './result/change_student_number';
import './result/domains';
import './result/download_results_dialog';
import './revenue/comparison-chart';
import './revenue/revenue';
import './revenue_forecast/revenue-forecast';
import './review/assign_reviewers';
import './review/overview';
import './review_requests/new';
import './runner/runner';
import './safe_exam_browser/safe-exam-browser-form';
import './scans/delete-assignment-scans';
import './scans/error-duplicate';
import './scans/error-identifier';
import './scans/error-student';
import './scans/process-status';
import './segment';
import './sessions/login';
import './sortable';
import './spreadsheet/spreadsheet';
import './switch-confirm';
import './tags/tag-value-search-autocomplete';
import './uploads/dropzone';
import './uploads/file-tabs';
import './uploads/image-rotation';
import './uploads/index';
import './users/enrich-users';
import './users/user-menu';
