import { MDCSnackbar } from '@material/snackbar';

function addAction(surface, href, label, customizer) {
  const actions = document.createElement('div');
  actions.classList.add('mdc-snackbar__actions');

  const actionButton = document.createElement('a');
  actionButton.setAttribute('href', href);
  actionButton.innerHTML = `
    <div class='mdc-button__ripple'></div>
    <span class='mikado-yellow mdc-button__label'>${label}</span>
  `;
  if (customizer) customizer(actionButton);
  surface.append(actionButton);
}

export default function (message, alert = false, href = null, label = null, customizer = null) {
  const snackbar = document.createElement('div');
  snackbar.classList.add('mdc-snackbar', 'mdc-snackbar--leading');
  snackbar.dataset.turboTemporary = true;
  if (alert) snackbar.classList.add('mdc-snackbar--alert');

  const snackbarSurface = document.createElement('div');
  snackbarSurface.classList.add('mdc-snackbar__surface');

  const snackbarLabel = document.createElement('div');
  snackbarLabel.classList.add('mdc-snackbar__label');
  snackbarLabel.setAttribute('role', 'status');
  snackbarLabel.setAttribute('aria-live', 'polite');
  snackbarLabel.innerHTML = message;

  snackbarSurface.appendChild(snackbarLabel);
  snackbar.appendChild(snackbarSurface);

  if (href && label) addAction(snackbarSurface, href, label, customizer);

  document.body.appendChild(snackbar);

  const mdcSnackbar = new MDCSnackbar(snackbar);
  mdcSnackbar.listen('MDCSnackbar:closed', function () {
    this.remove();
  });
  mdcSnackbar.timeoutMs = 10000;
  mdcSnackbar.open();
  return mdcSnackbar;
}
