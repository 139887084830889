/* global I18n */

import onmount from 'onmount';
import snackbar from '@components/snackbar';

onmount('[data-js-copy-to-clipboard]', function () {
  const self = this;
  const copyButton = self.querySelector('[data-js-copy]');
  const content = self.getAttribute('data-content');

  function handleClick() {
    navigator.clipboard.writeText(content);
    snackbar(I18n.t('js.general.successfully_copied'), false);
  }

  copyButton.addEventListener('click', handleClick);
});
