import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['count', 'max', 'chip', 'subquestion'];

  hideChip() {
    this.chipTarget.classList.add('d-none');
  }

  showChip() {
    this.chipTarget.classList.remove('d-none');
  }

  toggleChipVisibility() {
    if (this.subquestionTargets.length === 0) {
      this.hideChip();
    } else {
      this.showChip();
    }
  }

  toggleChipColor(completed) {
    if (completed === this.subquestionTargets.length) {
      this.chipTarget.classList.replace('chip-danger', 'chip-progress');
    } else {
      this.chipTarget.classList.replace('chip-progress', 'chip-danger');
    }
  }

  subquestionTargetConnected() {
    this.update();
  }

  subquestionTargetDisconnected() {
    this.update();
  }

  update() {
    let completed = 0;
    this.subquestionTargets.forEach((subquestion) => {
      if (subquestion.getAttribute(['data-grading-scheme']) === 'true') {
        completed += 1;
      }
    });

    this.toggleChipVisibility(completed);
    this.countTarget.innerText = completed;
    this.maxTarget.innerText = this.subquestionTargets.length;
    this.toggleChipColor(completed);
  }
}
