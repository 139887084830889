import onmount from 'onmount';
import TimeMe from 'timeme.js/timeme';

onmount('[data-js-record-page-view]', function () {
  TimeMe.startTimer();

  const { route } = this.dataset;
  const data = {
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
    route
  };

  function sendPageView() {
    data.duration = TimeMe.getTimeOnCurrentPageInSeconds();
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    navigator.sendBeacon('/page_views', blob);
  }
  this.sendPageView = sendPageView;

  function recordPageView() {
    if (document.visibilityState === 'hidden') {
      sendPageView();
    } else {
      TimeMe.resetAllRecordedPageTimes();
      TimeMe.startTimer();
    }
  }
  this.recordPageView = recordPageView;

  document.addEventListener('visibilitychange', this.recordPageView);
}, function () {
  this.sendPageView();
  document.removeEventListener('visibilitychange', this.recordPageView);
});
