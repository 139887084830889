import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

// Connects to data-controller="side-panel"
export default class extends Controller {
  static targets = ['panel', 'scrim'];

  connect() {
    if (this.hasPanelTarget) useClickOutside(this, { element: this.panelTarget });
  }

  panelTargetConnected(element) {
    useClickOutside(this, { element });
  }

  open() {
    this.showScrim();
    this.expandPanel();
  }

  close() {
    this.hideScrim();
    this.collapsePanel();
  }

  showScrim() {
    this.scrimTarget.style.display = 'block';
  }

  hideScrim() {
    this.scrimTarget.style.display = 'none';
  }

  expandPanel() {
    this.panelTarget.style.width = '600px';
  }

  collapsePanel() {
    this.panelTarget.style.width = '0';
  }

  clickOutside() {
    this.close();
  }
}
