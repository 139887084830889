/* global I18n */

import onmount from 'onmount';
import { MDCTextField } from '@material/textfield';
import { handleTimeChange } from '@modules/handle-time-change';

onmount('[data-js-publication-timeslot-form]', function () {
  const openDateTimeEl = this.querySelector('[data-js-publish-start-datetime]');
  const deadlineDateTimeEl = this.querySelector('[data-js-publish-end-datetime]');
  const startConversationDateTimeEl = this.querySelector('[data-js-conversation-start-datetime]');
  const endConversationDateTimeEl = this.querySelector('[data-js-conversation-end-datetime]');
  const allowConversations = this.querySelector('[data-js-allow-conversations]');
  const directly = this.querySelector('[data-js-publish-directly]');
  const timeframe = this.querySelector('[data-js-publish-timeframe]');
  const directlyInputs = this.querySelectorAll('[data-js-publish-directly-input]');
  let language = I18n.locale;
  if (language === 'nl_vo') language = 'nl';
  if (language === 'en') language = 'default';

  let openDateTime;
  let deadlineDateTime;
  let startConversationDateTime;
  let endConversationDateTime;

  async function getFlatpickr() {
    const [module] = await Promise.all([
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/nl'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/de'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/fr'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/es'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/pt'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/it'),
      import(/* webpackChunkName: "flatpickr" */ 'flatpickr/dist/l10n/pl')
    ]);
    return module.default;
  }

  function handleFlatpickrClick(a, b, c) {
    if (directly.checked) {
      c.close();
    }
  }

  function enableDateTimeInput(input) {
    const mdcTextField = input.closest('.mdc-text-field');
    if (!mdcTextField.MDCTextField) {
      mdcTextField.MDCTextField = new MDCTextField(mdcTextField);
      mdcTextField.setAttribute('data-mdc-auto-init-state', 'initialized');
    }

    input.closest('.mdc-text-field').MDCTextField.disabled = false;
    input.nextSibling.disabled = false;
  }

  function disableDateTimeInput(input) {
    const mdcTextField = input.closest('.mdc-text-field');
    if (!mdcTextField.MDCTextField) {
      mdcTextField.MDCTextField = new MDCTextField(mdcTextField);
      mdcTextField.setAttribute('data-mdc-auto-init-state', 'initialized');
    }

    mdcTextField.MDCTextField.disabled = true;
    input.nextSibling.disabled = true;
  }

  function handleDirectlyChange() {
    [openDateTime, deadlineDateTime].forEach((flatpickr) => { flatpickr.clear(); });
    [openDateTimeEl, deadlineDateTimeEl].forEach((input) => {
      input.closest('.mdc-text-field').classList.remove('mdc-text-field--focused');
      disableDateTimeInput(input);
    });
    directlyInputs.forEach((input) => { input.disabled = false; });
  }

  function handleTimeframeChange() {
    enableDateTimeInput(openDateTimeEl);
    enableDateTimeInput(deadlineDateTimeEl);

    directlyInputs.forEach((input) => { input.disabled = true; });
  }

  function handleAllowConversationsChange() {
    [startConversationDateTimeEl, endConversationDateTimeEl].forEach((input) => {
      if (allowConversations.checked) {
        enableDateTimeInput(input);
      } else {
        disableDateTimeInput(input);
      }
    });
  }

  function addFlatpickrEventListeners() {
    [openDateTime, deadlineDateTime, startConversationDateTime, endConversationDateTime].forEach((flatpickr) => {
      if (flatpickr === openDateTime || flatpickr === deadlineDateTime) {
        flatpickr.config.onOpen.push(handleFlatpickrClick);
      }
      flatpickr.input.addEventListener('keyup', handleTimeChange);
    });
  }

  function handleFlatpickrChange() {
    [openDateTimeEl, deadlineDateTimeEl, startConversationDateTimeEl, endConversationDateTimeEl].forEach((input) => {
      input.setCustomValidity('');
    });
  }

  getFlatpickr().then((flatpickr) => {
    [openDateTime, deadlineDateTime, startConversationDateTime, endConversationDateTime] = flatpickr([
      openDateTimeEl,
      deadlineDateTimeEl,
      startConversationDateTimeEl,
      endConversationDateTimeEl], {
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y H:i',
      enableTime: true,
      time_24hr: true,
      dateFormat: 'Y-m-d H:i',
      locale: language,
      onChange: handleFlatpickrChange,
      minuteIncrement: 1
    });

    if (directly.checked) handleDirectlyChange();
    if (allowConversations && !allowConversations.checked) handleAllowConversationsChange();

    addFlatpickrEventListeners();
  });
  if (timeframe) timeframe.addEventListener('change', handleTimeframeChange);
  if (directly) directly.addEventListener('change', handleDirectlyChange);
  if (allowConversations) allowConversations.addEventListener('change', handleAllowConversationsChange);
});
